import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { I18nService } from '@app/i18n';
import { Logger, UntilDestroy, untilDestroyed } from '@app/shared';
import { MsalBroadcastService } from '@azure/msal-angular';
import { EventMessage, EventType } from '@azure/msal-browser';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { merge } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

const log = new Logger('App');

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private translateService: TranslateService,
    private i18nService: I18nService,
    public swUpdate: SwUpdate,
    private msalBroadcastService: MsalBroadcastService
  ) {
    this.checkForUpdates();
  }

  async checkForUpdates() {
    if (await this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates.subscribe((event) => {
        if (event.type === 'VERSION_READY') {
          window.location.reload();
        }
      });
  
      try {
        await this.swUpdate.checkForUpdate();
      } catch (error) {
        console.error('Could not check for app updates', error);
      }
    }
  }

  redirectToLogin(msalSubject: EventMessage){
    switch (msalSubject.eventType) {
      case EventType.LOGIN_FAILURE:
        this.router.navigateByUrl('/login');
        break;
      case EventType.HANDLE_REDIRECT_START:
        document.cookie = 'msal.interaction.status=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT';
        break;
      case EventType.ACQUIRE_TOKEN_BY_CODE_FAILURE:
        this.router.navigateByUrl('/login');
        break;
      case EventType.SSO_SILENT_FAILURE:
        this.router.navigateByUrl('/login');
        break;
      case EventType.ACQUIRE_TOKEN_FAILURE:
        this.router.navigateByUrl('/login');
        break;
      case EventType.LOGOUT_FAILURE:
        this.router.navigateByUrl('/login');
        break;
      default:

        break;
    }
  }

  ngOnInit() {
    if (environment.production) {
      Logger.enableProductionMode();
    }

    log.debug('Start');
    log.debug(window.location.origin)
    this.i18nService.init(environment.defaultLanguage, environment.supportedLanguages);

    const onNavigationEnd = this.router.events.pipe(filter((event) => event instanceof NavigationEnd));
    merge(this.translateService.onLangChange, onNavigationEnd)
      .pipe(
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        switchMap((route) => route.data),
        untilDestroyed(this)
      )
      .subscribe((event) => {
        const title = event.title;
        if (title) {
          this.titleService.setTitle(this.translateService.instant(title));
        }
      });


      this.msalBroadcastService.msalSubject$.subscribe({
        next: (msalSubject) => {
          this.handleMsalEvent(msalSubject);
        },
      });
  }

  handleMsalEvent(msalSubject: EventMessage){
    this.redirectToLogin(msalSubject);
  }

  ngOnDestroy() {
    this.i18nService.destroy();
  }
}
