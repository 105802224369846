import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { I18nModule } from '@app/i18n';
import { AuthModule } from '@app/core/auth';
import { MenuComponent } from './component/menu/menu.component';
import { FooterComponent } from './component/footer/footer.component';
import { HomeComponent } from './component/home/home.component';
import { NotFoundComponent } from './component/not-found/not-found.component';
import { MainComponent } from './component/main/main.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { UnauthorizedComponent } from './component/unauthorized/unauthorized.component';
import { TourComponent } from './component/tour/tour.component';
import { SharedModule } from '@app/shared';
import { AcceptContractComponent } from '@app/modules/technical-delivery/components/deliveries/accept-contract/accept-contract.component';
import  {  PdfViewerModule  }  from  'ng2-pdf-viewer';
import { AltSidebarModule } from 'ng-alt-sidebar';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { LoadingBarModule } from '@ngx-loading-bar/core';

@NgModule({
  imports: [
    PdfViewerModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    LoadingBarModule,
    CommonModule,
    NgxSpinnerModule,
    TranslateModule,
    NgbModule,
    AltSidebarModule,
    AuthModule,
    I18nModule,
    RouterModule,
    SharedModule,
  ],
  declarations: [
    MenuComponent,
    HomeComponent,
    FooterComponent,
    NotFoundComponent,
    MainComponent,
    UnauthorizedComponent,
    TourComponent,
    AcceptContractComponent
  ],
  exports: [MenuComponent, NgxSpinnerModule, HomeComponent, FooterComponent, NotFoundComponent, MainComponent, AcceptContractComponent],
})
export class NavegationModule {}
