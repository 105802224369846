import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import ptBr from '@angular/common/locales/pt';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AuthModule } from '@app/core/auth';
import { SharedModule } from '@app/shared';
import {
  MsalRedirectComponent,
} from '@azure/msal-angular';
import { environment } from '@env/environment';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { TranslateModule } from '@ngx-translate/core';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ToastrModule } from 'ngx-toastr';
import { TourNgBootstrapModule } from 'ngx-ui-tour-ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavegationModule } from './core/navegation/navegation.module';
import { providers } from './providers';

registerLocaleData(ptBr)

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    FormsModule,
    HttpClientModule,
    RouterModule,
    SweetAlert2Module.forRoot(),
    TranslateModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 1500,
      enableHtml: true
    }),
    NgMultiSelectDropDownModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    TourNgBootstrapModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    LoadingBarModule,
    NgbModule,
    SharedModule,
    NavegationModule,
    AuthModule,
    NoopAnimationsModule,
    AppRoutingModule, // must be imported as the last module as it contains the fallback route
  ],
  declarations: [AppComponent],
  providers: providers,
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
